<template>
  <div>
    <div class="d-flex justify-center pl-4 pr-4">
      <div>I commit to pay at counter the due amount, accepting that in case I don’t, I won’t be able to retrieve the
        equipment, and I’ll lose the amount paid, plus still owing the amount due.
      </div>
    </div>
    <div class="d-flex justify-content-around mt-2">
      <div
        style="
        display: flex;
        align-items: center;
        "
      >
        <div style="margin-right: 5px;">
          Deposit
        </div>
        <component
          :is="creditCardOnFileFiles['deposit'].type"
          key="deposit"
          :ref="`deposit_ref`"
          v-model="paymentForm['deposit']"
          v-bind="getProps('deposit', creditCardOnFileFiles)"
          class="mb-0"
        />
      </div>
      <div
        style="
        display: flex;
        align-items: center;
        "
      >
        <div style="margin-right: 5px;">
          Charge
        </div>
        <component
          :is="creditCardOnFileFiles['charge'].type"
          key="charge"
          :ref="`charge_ref`"
          v-model="paymentForm['charge']"
          v-bind="getProps('charge', creditCardOnFileFiles)"
          class="mb-0"
        />
      </div>
    </div>
    <div class="mt-1">
      <span class="font-weight-bolder">
        {{ 'Please enter your credit card information' }}
      </span>
      <b-row class="mt-1">
        <b-col cols="6">
          <component
            :is="payWithCreditCardFiles[field].type"
            v-for="field in ['nameOnCard']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="paymentForm[field]"
            v-bind="getProps(field, payWithCreditCardFiles)"
            class="mb-0 flex"
          />
        </b-col>
        <b-col cols="6">
          <component
            :is="payWithCreditCardFiles[field].type"
            v-for="field in ['cardNumber']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="paymentForm[field]"
            v-bind="getProps(field, payWithCreditCardFiles)"
            class="mb-0"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="4">
          <component
            :is="payWithCreditCardFiles[field].type"
            v-for="field in ['exp_date']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="paymentForm[field]"
            v-bind="getProps(field, payWithCreditCardFiles)"
            class="mb-0 flex"
          />
        </b-col>
        <b-col cols="4">
          <component
            :is="payWithCreditCardFiles[field].type"
            v-for="field in ['cvv']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="paymentForm[field]"
            v-bind="getProps(field, payWithCreditCardFiles)"
            class="mb-0"
          />
        </b-col>
        <b-col cols="4">
          <component
            :is="payWithCreditCardFiles[field].type"
            v-for="field in ['zip']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="paymentForm[field]"
            v-bind="getProps(field, payWithCreditCardFiles)"
            class="mb-0"
          />
        </b-col>
      </b-row>
      <component
        :is="creditCardOnFileFiles[field].type"
        v-for="field in ['keepCardInformationOnFile']"
        :key="field"
        :ref="`${field}_ref`"
        v-model="paymentForm[field]"
        class="mt-1"
        :field="creditCardOnFileFiles[field]"
        :name="field"
      />
    </div>
  </div>
</template>
<script>
import { BAlert, BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/payment/components/config'
import { getProps } from '@core/utils/utils'

export default {
  name: 'PayWithCreditCard',
  components: { BRow, BCol, BAlert },
  computed: {
    paymentForm() {
      return this.$store.state[this.MODULE_NAME].paymentForm
    },
    cardList() {
      return this.$store.state[this.MODULE_NAME].cardList
    },
  },
  setup() {
    const MODULE_NAME = 'payment'
    const { creditCardOnFileFiles, tableCardListColumns, payWithCreditCardFiles } = config()
    return {
      MODULE_NAME,
      creditCardOnFileFiles,
      tableCardListColumns,
      payWithCreditCardFiles,
      getProps,
    }
  },
}
</script>
<style scoped lang="scss">

</style>
