<template>
  <div>
    <b-modal
      id="displayed-error-message-from-bbpos-system-model"
      centered
      hide-header
      body-class="displayed-error-message-from-bbpos-system-body py-2"
      footer-class="displayed-error-message-from-bbpos-system-footer"
      modal-class="displayed-error-message-from-bbpos-system"
      cancel-variant="outline-primary"
      hide-footer
      size="lg"
      @hide="hide"
    >
      <h5 class="font-weight-bolder text-center">
        {{ content }}
      </h5>
      <div class="d-flex justify-content-between">

        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="cancel"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t('Cancel') }}
        </b-button>

        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="success"
          @click="tryAgain"
        >
          {{ $t('Try again') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import config from '@/views/main/payment/components/config'
import { getProps } from '@core/utils/utils'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'PointOfSaleEMV',
  components: {
    BButton,
  },
  data() {
    return {
      content: '',
    }
  },
  computed: {
    paymentForm() {
      return this.$store.state[this.MODULE_NAME].paymentForm
    },
  },
  methods: {
    cancel() { this.$emit('errorCancel') },
    tryAgain() { this.$emit('tryAgain') },
    hide() { this.$bvModal.hide('displayed-error-message-from-bbpos-system-model') },
    show() { this.$bvModal.show('displayed-error-message-from-bbpos-system-model') },
  },
  setup() {
    const MODULE_NAME = 'payment'
    const { creditCardOnFileFiles, tableCardListColumns, payWithACHFiles } = config()
    return {
      MODULE_NAME,
      creditCardOnFileFiles,
      payWithACHFiles,
      tableCardListColumns,
      getProps,
    }
  },
}
</script>
<style lang="css">
.displayed-error-message-from-bbpos-system-model > .modal-dialog-centered{
  max-width: max-content;
}
</style>
