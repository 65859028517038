<template>
  <div>
    <div>
      <div class="d-flex justify-center pl-4 pr-4">
        <div>I commit to pay at counter the due amount, accepting that in case I don’t, I won’t be able to retrieve the
          equipment, and I’ll lose the amount paid, plus still owing the amount due.
        </div>
      </div>
      <div class="d-flex justify-content-around mt-2">

        <div
          style="
        display: flex;
        align-items: center;
        "
        >
          <div style="margin-right: 5px;">
            Deposit
          </div>
          <component
            :is="creditCardOnFileFiles['deposit'].type"
            key="deposit"
            :ref="`deposit_ref`"
            v-model="paymentForm['deposit']"
            v-bind="getProps('deposit', creditCardOnFileFiles)"
            class="mb-0"
          />
        </div>
        <div
          style="
        display: flex;
        align-items: center;
        "
        >
          <div style="margin-right: 5px;">
            Charge
          </div>
          <component
            :is="creditCardOnFileFiles['charge'].type"
            key="charge"
            :ref="`charge_ref`"
            v-model="paymentForm['charge']"
            v-bind="getProps('charge', creditCardOnFileFiles)"
            class="mb-0"
          />
        </div>
      </div>
      <div class="mt-2">
        <span class="font-weight-bolder">
          {{ 'Please enter your ACH information' }}
        </span>
        <b-row class="mt-1">
          <b-col cols="6">
            <component
              :is="payWithACHFiles[field].type"
              v-for="field in ['routing_number']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="paymentForm[field]"
              v-bind="getProps(field, payWithACHFiles)"
              class="mb-0 flex"
            />
          </b-col>
          <b-col cols="6">
            <component
              :is="payWithACHFiles[field].type"
              v-for="field in ['account_number']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="paymentForm[field]"
              v-bind="getProps(field, payWithACHFiles)"
              class="mb-0"
            />
          </b-col>
        </b-row>
        <div class="mt-2">
          <span class="font-weight-bolder">
            {{ 'Account Type' }}
          </span>
          <component
            :is="payWithACHFiles[field].type"
            v-for="field in ['account_type']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="paymentForm[field]"
            v-bind="getProps(field, payWithACHFiles)"
            :name="field"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import config from '@/views/main/payment/components/config'
import { getProps } from '@core/utils/utils'

export default {
  name: 'PayWithACH',
  components: { BRow, BCol },
  computed: {
    paymentForm() {
      return this.$store.state[this.MODULE_NAME].paymentForm
    },
    cardList() {
      return this.$store.state[this.MODULE_NAME].cardList
    },
  },
  setup() {
    const MODULE_NAME = 'payment'
    const { creditCardOnFileFiles, tableCardListColumns, payWithACHFiles } = config()
    return {
      MODULE_NAME,
      creditCardOnFileFiles,
      payWithACHFiles,
      tableCardListColumns,
      getProps,
    }
  },
}
</script>
<style scoped lang="scss">

</style>
