<template>
  <div>
    <div class="d-flex justify-center pl-4 pr-4">
      <div>I commit to pay at counter the due amount, accepting that in case I don’t, I won’t be able to retrieve the
        equipment, and I’ll lose the amount paid, plus still owing the amount due.
      </div>
    </div>
    <div class="d-flex justify-content-around mt-2">

      <div
        style="
        display: flex;
        align-items: center;
        "
      >
        <div style="margin-right: 5px;">
          Deposit
        </div>
        <component
          :is="creditCardOnFileFiles['deposit'].type"
          key="deposit"
          :ref="`deposit_ref`"
          v-model="paymentForm['deposit']"
          v-bind="getProps('deposit', creditCardOnFileFiles)"
          class="mb-0"
        />
      </div>
      <div
        style="
        display: flex;
        align-items: center;
        "
      >
        <div style="margin-right: 5px;">
          Charge
        </div>
        <component
          :is="creditCardOnFileFiles['charge'].type"
          key="charge"
          :ref="`charge_ref`"
          v-model="paymentForm['charge']"
          v-bind="getProps('charge', creditCardOnFileFiles)"
          class="mb-0"
        />
      </div>
    </div>
    <l-table-list-collector
      ref="lTableRef"
      :is-searchable="false"
      :module-name="MODULE_NAME"
      :table-columns="tableCardListColumns"
      :is-footer="true"
      :fetched-data="cardList"
      class="mt-1"
    >
      <template #cell(actions)="{data: { item }}">
        <div class="pl-2">
          <b-form-radio-group
            v-model="selectedCard"
            name="cardSelection"
          >
            <b-form-radio :value="item.token">
              <component
                :is="creditCardOnFileFiles['selectedCart'].type"
                key="deposit"
                :ref="`deposit_ref_${item.id}`"
                v-model="paymentForm['selectedCart']"
                v-bind="getProps('selectedCart', creditCardOnFileFiles)"
                class="mb-0"
              />
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </template>
      <template #cell(type)="{data: { item }}">
        <div class="pl-2">
          {{ item.type }}
        </div>
      </template>
      <template #cell(network)="{data: { item }}">
        <div class="pl-2">
          {{ item.network }}
        </div>
      </template>
      <template #cell(lastFourDigits)="{data: { item }}">
        <div class="pl-2">
          {{ item.masked_number }}
        </div>
      </template>
      <template #cell(billingDddress)="{data: { item }}">
        <div class="pl-2">
          {{ item.address }}
        </div>
      </template>
    </l-table-list-collector>
  </div>
</template>
<script>
import { getProps } from '@core/utils/utils'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BAlert, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'
import config from './config'

export default {
  name: 'CreditCardOnFile',
  components: {
    BAlert, BFormRadioGroup, BFormRadio, LTableListCollector,
  },
  data() {
    return {
      selectedCard: null,
    }
  },
  computed: {
    paymentForm() {
      return this.$store.state[this.MODULE_NAME].paymentForm
    },
    cardList() {
      return this.$store.state[this.MODULE_NAME].cardList
    },
  },
  methods: {
    getCardType(cardNumber) {
      // eslint-disable-next-line no-param-reassign
      cardNumber = cardNumber.replace(/\s+/g, '').replace(/-/g, '')

      if (cardNumber.startsWith('4')) {
        return 'Visa'
      }
      if (cardNumber.startsWith('5')) {
        return 'MasterCard'
      }
      return ''
    },
  },
  setup() {
    const MODULE_NAME = 'payment'
    const { creditCardOnFileFiles, tableCardListColumns } = config()
    return {
      MODULE_NAME,
      creditCardOnFileFiles,
      tableCardListColumns,
      getProps,
    }
  },
}
</script>
<style scoped lang="scss">

</style>
