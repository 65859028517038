import {
  CHECKBOX_LEF,
  L_CURRENCY_MASK_INPUT, MASK_INPUT, RADIO_GROUP, SELECT_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  // Constants
  const paymentGatewayFields = {
    payment_gateway: {
      type: SELECT_INPUT,
      store: 'integrationsList',
      rules: 'required',
      options: {
        label: 'name',
      },
    },
  }

  // Filter Tabs
  const creditCardOnFileFiles = {
    email: {
      type: TEXT_INPUT,
      label: '',
      placeholder: 'Type...',
    },
    selectedCart: {
      type: RADIO_GROUP,
      label: '',
      placeholder: 'Type...',
    },
    deposit: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: 'Type...',
    },

    charge: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: 'Type...',
    },
    keepCardInformationOnFile: {
      type: CHECKBOX_LEF,
      label: 'Keep card information on file',
    },
  }
  const payWithCreditCardFiles = {
    nameOnCard: {
      type: TEXT_INPUT,
      label: 'Name on Card',
      placeholder: 'Enter the name that appears on the card',
      rules: 'required',
    },
    cardNumber: {
      type: MASK_INPUT,
      label: 'Card Number',
      placeholder: 'XXXX-XXXX-XXXX-XXXX',
      rules: 'required|length:16',
      options: {
        options: {
          creditCard: true,
        },
      },
    },
    exp_date: {
      type: MASK_INPUT,
      label: 'Exp. Date',
      rules: 'required|max:5',
      placeholder: 'MM/YY',
      options: {
        options: {
          date: true,
          datePattern: ['m', 'y'],
        },
        raw: false,
      },
    },
    zip: {
      type: TEXT_INPUT,
      label: 'Zip Code',
      rules: 'required|numeric',
      placeholder: 'XXXXX',
    },
    cvv: {
      type: TEXT_INPUT,
      label: 'CVV',
      placeholder: 'XXX',
      rules: 'required|numeric',
      // options: {
      //   options: {
      //   },
      // },
    },
  }

  const payWithACHFiles = {
    routing_number: {
      type: TEXT_INPUT,
      label: 'Routing Number',
      placeholder: 'Type...',
      rules: 'required',
    },

    account_number: {
      type: TEXT_INPUT,
      label: 'Account Number',
      placeholder: 'Type...',
      rules: 'required',
    },

    account_type: {
      type: RADIO_GROUP,
      label: '',
      placeholder: 'Type...',
      rules: 'required',
      list: [
        { value: 'Checking', text: 'Checking' },
        { value: 'Saving', text: 'Saving' },
      ],
    },
  }
  const tableCardListColumns = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '10%' },
    },
    {
      key: 'type',
      label: 'Type',
      thStyle: { width: '10%' },
    },
    {
      key: 'network',
      label: 'Network',
      thStyle: { width: '10%' },
      tBodyThStyle: { textAlign: 'center' },
    },
    {
      key: 'lastFourDigits',
      label: 'Last Four Digits',
      thStyle: { width: '20%' },
      tBodyThStyle: { textAlign: 'center' },
    },
    {
      key: 'billingDddress',
      label: 'Billing Address',
      thStyle: { width: '50%' },
    },
  ]

  return {
    creditCardOnFileFiles,
    tableCardListColumns,
    payWithACHFiles,
    payWithCreditCardFiles,
    paymentGatewayFields,
  }
}
