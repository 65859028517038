<template>
  <div>
    <b-modal
      id="insert-credit-carton-terminal-model"
      centered
      hide-header
      body-class="insert-credit-carton-terminal-model-body py-2"
      footer-class="insert-credit-carton-terminal-model-footer"
      modal-class="insert-credit-carton-terminal-model"
      cancel-variant="outline-primary"
      hide-footer
      size="lg"
      @hide="hide"
    >
      <h5 class="font-weight-bolder text-center ">
        {{ 'Insert Credit Cart on Terminal' }}
      </h5>

      <b-button
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="cancel"
      >
        <feather-icon
          icon="LCancelIcon"
          size="16"
        />
        {{ $t('Cancel') }}
      </b-button>
    </b-modal>

  </div>
</template>
<script>
import config from '@/views/main/payment/components/config'
import { getProps } from '@core/utils/utils'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'PointOfSaleEMV',
  components: {
    BButton,
  },
  computed: {
    paymentForm() {
      return this.$store.state[this.MODULE_NAME].paymentForm
    },
  },
  methods: {
    cancel() {
      this.hide()
      this.$emit('paymentCancel')
    },
    hide() { this.$bvModal.hide('insert-credit-carton-terminal-model') },
    show() { this.$bvModal.show('insert-credit-carton-terminal-model') },
  },
  setup() {
    const MODULE_NAME = 'payment'
    const { creditCardOnFileFiles, tableCardListColumns, payWithACHFiles } = config()
    return {
      MODULE_NAME,
      creditCardOnFileFiles,
      payWithACHFiles,
      tableCardListColumns,
      getProps,
    }
  },
}
</script>
<style  lang="css">
.insert-credit-carton-terminal-model > .modal-dialog-centered{
  max-width: max-content;
}
</style>
