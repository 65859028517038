<template>
  <div>
    <b-modal
      id="charge-succeded-model"
      centered
      hide-header
      body-class="charge-succeded-model-body py-2"
      footer-class="charge-succeded-model-footer"
      modal-class="charge-succeded-model"
      cancel-variant="outline-primary"
      hide-footer
      size="lg"
      @hide="hide"
    >
      <h5 class="font-weight-bolder text-center ">
        {{ 'Charge succeded' }}
      </h5>
      <div class="text-center">
        {{ 'Send a copy of the sales order to an email address ?' }}
      </div>
      <component
        :is="creditCardOnFileFiles['email'].type"
        key="deposit"
        v-model="paymentForm['email']"
        v-bind="getProps('email', creditCardOnFileFiles)"
        class="mb-0"
      />
      <div class="text-center">
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder mt-1"
          variant="success"
          style="padding: 0 30px"
          @click="ok"
        >
          {{ $t('OK') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import config from '@/views/main/payment/components/config'
import { getProps } from '@core/utils/utils'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'PointOfSaleEMV',
  components: { BButton },
  computed: {
    paymentForm() {
      return this.$store.state[this.MODULE_NAME].paymentForm
    },
  },
  methods: {
    ok() {
      this.$emit('ok')
    },
    hide() { this.$bvModal.hide('charge-succeded-model') },
    show() { this.$bvModal.show('charge-succeded-model') },
  },
  setup() {
    const MODULE_NAME = 'payment'
    const { creditCardOnFileFiles, tableCardListColumns, payWithACHFiles } = config()
    return {
      MODULE_NAME,
      creditCardOnFileFiles,
      payWithACHFiles,
      tableCardListColumns,
      getProps,
    }
  },
}
</script>
<style lang="css">
.charge-succeded-model > .modal-dialog-centered{
  max-width: max-content;
}
</style>
